@font-face {
  font-family: 'SpaceGrotesk';
  src: url("assets/fonts/SpaceGrotesk/SpaceGrotesk-Light.woff") format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'SpaceGrotesk';
  src: url('assets/fonts/SpaceGrotesk/SpaceGrotesk-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face{
  font-family: 'SpaceGrotesk';
  src: url('assets/fonts/SpaceGrotesk/SpaceGrotesk-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face{
  font-family: 'SpaceGrotesk';
  src: url('assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face{
  font-family: 'SpaceGrotesk';
  src: url('assets/fonts/SpaceGrotesk/SpaceGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/Gilroy/Gilroy-Light.otf') format('opentype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/Gilroy/Gilroy-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/Gilroy/Gilroy-Regular.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('assets/fonts/Gilroy/Gilroy-Regular.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
}
